import { transformExtent } from "ol/proj";
import ImageLayer from 'ol/layer/Image.js';
import XYZ from "ol/source/XYZ";
import TileLayer from "ol/layer/Tile";
import Static from 'ol/source/ImageStatic.js';
import { NEARMAP_IMAGE_API } from "../../helper/ApiUrl";

const setNearmap = (map, date, nearmapcoords, tileGridLyr) => {
  if (map && date && nearmapcoords.length) {
    nearmapcoords.forEach((item) => {
      var projection = transformExtent(item, "EPSG:4326", "EPSG:3857");

      var tileGridImage = new TileLayer({
        extent: projection,
        source: new XYZ({
          url: `https://api.nearmap.com/tiles/v3/Vert/{z}/{x}/{y}.jpg?apikey=${process.env.REACT_APP_NEARMAP_KEY}&until=${date}`,
        }),
        crossOrigin: 'Anonymous'
      });
      tileGridLyr.current.getLayers().array_.push(tileGridImage);
      map.getLayers().forEach(function (layer) {
        if (layer && layer.get("title") && layer.get("title") === "Tile Imagery")
          map.removeLayer(layer);
      });
      map.addLayer(tileGridLyr.current);
    });
  }
};

export default setNearmap;